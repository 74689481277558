///
/// Forty by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Main */

	#main {
		background-color: _palette(bg-alt);

		> * {
			border-top: solid 1px _palette(border);

			&:first-child {
				border-top: 0;
			}

			@include inner;
		}

		&.alt {
			background-color: transparent;
			border-bottom: solid 1px _palette(border);
		}
	}