@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'fontawesome-all.min.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,600,600italic');

/*
	Forty by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

// Breakpoints.

	@include breakpoints((
		xlarge:    (1281px,   1680px   ),
		large:     (981px,    1280px   ),
		medium:    (737px,    980px    ),
		small:     (481px,    736px    ),
		xsmall:    (361px,    480px    ),
		xxsmall:   (null,     360px    )
	));

// Mixins.

	@mixin inner {
		> .inner {
			@include padding(4em, 0);
			margin: 0 auto;
			max-width: _size(inner);
			width: calc(100% - 6em);

			@include breakpoint('<=small') {
				@include padding(3em, 0);
				width: calc(100% - 3em);
			}
		}
	}

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/row';
	@import 'components/section';
	@import 'components/form';
	@import 'components/box';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/pagination';
	@import 'components/table';
	@import 'components/button';
	@import 'components/tiles';
	@import 'components/contact-method';
	@import 'components/spotlights';

// Layout.

	@import 'layout/header';
	@import 'layout/banner';
	@import 'layout/main';
	@import 'layout/contact';
	@import 'layout/footer';
	@import 'layout/wrapper';
	@import 'layout/menu';