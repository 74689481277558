///
/// Forty by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Footer */

	#footer {
		.copyright {
			font-size: 0.8em;
			list-style: none;
			padding-left: 0;

			li {
				border-left: solid 1px _palette(border);
				color: rgb(244, 244, 255);
				display: inline-block;
				line-height: 1;
				margin-left: 1em;
				padding-left: 1em;

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		@include breakpoint('<=xsmall') {
			.copyright {
				li {
					display: block;
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
					line-height: inherit;
				}
			}
		}
	}